<template>
  <!-- 新增考勤规则 -->
  <div>
    <div class="box-card" style="text-align: left;">
      <!-- 主要内容 -->
      <div class="content_box">
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="150px" class="demo-ruleForm"
          @submit.native.prevent>
          <el-form-item label="规则名称：" prop="rule_name">
            <el-input v-model="addForm.rule_name" maxlength="30" placeholder="请输入规则名称"
              @input="(e) => (addForm.rule_name = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="组织：" prop="organize_id">
            <el-select popper-class="my-select" filterable v-model="addForm.organize_id" placeholder="请选择组织" clearable
              @change="selectOrganize()">
              <el-option v-for="item in organizationSeleteList" :key="item._id" :label="item.organize_name"
                :value="item._id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工作日：" prop="holiday">
            <lbButton type="setting" icon="shebeipeizhi" @click="openConfigDate">配置</lbButton>
          </el-form-item>
          <el-form-item label="打卡时间：" prop="punch_type" class="punchType">
            <el-select popper-class="my-select" v-model="addForm.punch_type" placeholder="请选择打卡方式"
              @change="punchTypeChange">
              <!-- 1:固定班制 2：自由班制 -->
              <el-option label="固定班制" :value="1"></el-option>
              <el-option label="自由班制" :value="2"></el-option>
            </el-select>
            <el-select popper-class="my-select" v-model="addForm.spring_type" placeholder="请选择打卡规则"
              style="margin-left:10px;" @change="springTypeChange">
              <el-option label="无特殊规则" :value="0"></el-option>
              <el-option label="按时段打卡" :value="1"></el-option>
              <el-option label="可弹性打卡" :value="2" v-if="addForm.punch_type == 1"></el-option>
            </el-select>
            <!-- <el-radio-group v-model="radio" @change="radioChange">
                <el-radio label="is_time_interval">打卡时段</el-radio>
                <el-radio label="is_spring" v-if="addForm.punch_type == 1">弹性打卡</el-radio>
                <el-radio label="no">不做任何配置</el-radio>
              </el-radio-group> -->
            <!-- <el-checkbox v-model="addForm.is_time_interval" :disabled="addForm.is_spring || addForm.time_interval.length > 1" style="margin-left: 15px;">打卡时段</el-checkbox>
              <el-checkbox v-model="addForm.is_spring" v-if="addForm.punch_type == 1" :disabled="addForm.is_time_interval">弹性打卡</el-checkbox> -->
          </el-form-item>
          <el-form-item label="" prop="work_hour" v-if="addForm.punch_type == 2">
            <div class="work_hour_box">
              <div>工作时长</div>
              <el-form-item prop="work_hour"
                :rules="[{ required: true, message: '请输入工作时长', trigger: 'blur' }, { pattern: /^\+?[1-9][0-9]*$/, message: '必须为正整数', trigger: 'blur' }]"
                style="margin-left:10px;width:100%;">
                <el-input v-model="addForm.work_hour" maxlength="2" @input="(e) => (addForm.work_hour = validSpace(e))"
                  placeholder="请输入工作时长"></el-input>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item label="" prop="work_shift" v-if="addForm.punch_type == 1">
            <div v-for="(item, index) in addForm.work_shift" :key="index" class="work_shift_box">
              上班时间
              <el-form-item :prop="'work_shift[' + index + '].to_work_time'"
                :rules="{ required: true, message: '请选择上班时间', trigger: 'blur' }">
                <el-time-picker v-model="item.to_work_time" :editable="false" :clearable="false" :picker-options="{
                  selectableRange: toWorkTime(index), format: 'HH:mm'
                }" :default-value="date + '00:00:00'" value-format="HH:mm"
                  :format="item.isToWorkTimeNextDate ? 'HH:mm（次日）' : 'HH:mm'" placeholder="请选择上班时间" align="center"
                  @change="toWorkTimeChange(index, item.to_work_time)"></el-time-picker>
                <!-- <el-time-select v-model="item.to_work_time" :editable="false" :clearable="false" :picker-options="{start: '00:00',step: '00:30',end: '23:30', }" placeholder="请选择上班时间"></el-time-select> -->
              </el-form-item>
              下班时间
              <el-form-item :prop="'work_shift[' + index + '].off_work_time'"
                :rules="{ required: true, message: '请选择下班时间', trigger: 'blur' }">
                <el-time-picker v-model="item.off_work_time" :editable="false" :clearable="false" :picker-options="{
                  selectableRange: offWorkTime(index), format: 'HH:mm'
                }" :default-value="date + '00:00:00'" value-format="HH:mm"
                  :format="item.isOffWorkTimeNextDate ? 'HH:mm（次日）' : 'HH:mm'" placeholder="请选择下班时间" align="center"
                  @change="offWorkTimeChange(index, item.off_work_time)"></el-time-picker>
                <!-- <el-time-select v-model="item.off_work_time" :editable="false" :clearable="false" :picker-options="{start: '00:00',step: '00:30',end: '23:30'}" placeholder="请选择下班时间" align="center" @change="offWorkTimeChange(index, item.off_work_time)"></el-time-select> -->
              </el-form-item>
              <el-form-item v-if="addForm.spring_type == 1 && index < 2" class="iconButton">
                <el-button icon="el-icon-plus" size="mini" circle @click="addBillingTime(index)"></el-button>
              </el-form-item>
              <el-form-item v-if="addForm.spring_type == 1 && index > 0" class="iconButton">
                <el-button icon="el-icon-minus" size="mini" circle @click="delBillingTime(index)"></el-button>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item label="" prop="time_interval" v-if="addForm.spring_type == 1">
            <div v-for="(item, index) in addForm.time_interval" :key="index" class="time_interval_box">
              <div v-if="addForm.time_interval.length > 1">第 {{ index + 1 }} 段</div>
              <div v-else>打卡时段</div>
              <div class="item_box">
                <div v-show="addForm.punch_type == 1">上班时间：
                  <span>{{ addForm.work_shift[index].isToWorkTimeNextDate ? addForm.work_shift[index].to_work_time +
                    '（次日）' : addForm.work_shift[index].to_work_time }}</span>
                </div>
                <el-form-item :prop="'time_interval[' + index + '].to_work_begin_time'"
                  :rules="{ required: true, message: '请选择上班可打卡时间', trigger: 'blur' }" class="time_interva_input">
                  <el-time-picker v-model="item.to_work_begin_time" :editable="false" :clearable="false" :picker-options="{
                    selectableRange: toWorkBeginTime(index), format: 'HH:mm'
                  }" placeholder="请选择上班可打卡时间" value-format="HH:mm" format="HH:mm" align="center"
                    @change="toWorkBeginTimeChange(index, item.to_work_begin_time)"></el-time-picker>
                </el-form-item>
                <span>至</span>
                <el-form-item :prop="'time_interval[' + index + '].to_work_end_time'"
                  :rules="{ required: true, message: '请选择上班可打卡时间', trigger: 'blur' }" class="time_interva_input">
                  <el-time-picker v-model="item.to_work_end_time" :editable="false" :clearable="false" :picker-options="{
                    selectableRange: toWorkEndTime(index), format: 'HH:mm'
                  }" placeholder="请选择上班可打卡时间" value-format="HH:mm" format="HH:mm" align="center"></el-time-picker>
                </el-form-item>
                <span>可打上班卡</span>
              </div>
              <div class="item_box">
                <div v-show="addForm.punch_type == 1">下班时间：
                  <span>{{ addForm.work_shift[index].isOffWorkTimeNextDate ? addForm.work_shift[index].off_work_time +
                    '（次日）' : addForm.work_shift[index].off_work_time }}</span>
                </div>
                <el-form-item :prop="'time_interval[' + index + '].off_work_begin_time'"
                  :rules="{ required: true, message: '请选择下班可打卡时间', trigger: 'blur' }" class="time_interva_input">
                  <el-time-picker v-model="item.off_work_begin_time" :editable="false" :clearable="false" :picker-options="{
                    selectableRange: offWorkBeginTime(index), format: 'HH:mm'
                  }" value-format="HH:mm" format="HH:mm" placeholder="请选择下班可打卡时间" align="center"
                    @change="offWorkBeginTimeChange(index, item.off_work_begin_time)"></el-time-picker>
                </el-form-item>
                <span>至</span>
                <el-form-item :prop="'time_interval[' + index + '].off_work_end_time'"
                  :rules="{ required: true, message: '请选择下班可打卡时间', trigger: 'blur' }" class="time_interva_input">
                  <el-time-picker v-model="item.off_work_end_time" :editable="false" :clearable="false" :picker-options="{
                    selectableRange: offWorkEndTime(index), format: 'HH:mm'
                  }" value-format="HH:mm" format="HH:mm" placeholder="请选择下班可打卡时间" align="center"></el-time-picker>
                </el-form-item>
                <span>可打下班卡</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="" prop="elastic_punch_in" v-if="addForm.spring_type == 2">
            <div v-for="(item, index) in addForm.elastic_punch_in" :key="index">
              <div>弹性打卡</div>
              <div class="elastic_punch_in_box">
                上班晚到
                <el-form-item :prop="'elastic_punch_in[' + index + '].arrive_late_minute'"
                  :rules="[{ required: true, message: '请输入允许上班晚到时间', trigger: 'blur' }, { pattern: /^\+?[0-9][0-9]*$/, message: '必须为整数', trigger: 'blur' }]"
                  class="elastic_punch_in_box_input">
                  <el-input maxlength="4" v-model="item.arrive_late_minute"
                    @input="(e) => (item.arrive_late_minute = validSpace(e))"></el-input>
                </el-form-item>
                分钟内不算迟到，当月
                <el-form-item :prop="'elastic_punch_in[' + index + '].arrive_late_count'"
                  :rules="[{ required: true, message: '请输入允许上班晚到次数', trigger: 'blur' }, { pattern: /^\+?[0-9][0-9]*$/, message: '必须为整数', trigger: 'blur' }]"
                  class="elastic_punch_in_box_input">
                  <el-input maxlength="2" v-model="item.arrive_late_count"
                    @input="(e) => (item.arrive_late_count = validSpace(e))"></el-input>
                </el-form-item>
                次有效
              </div>
              <div class="elastic_punch_in_box">
                下班早走
                <el-form-item :prop="'elastic_punch_in[' + index + '].leave_early_minute'"
                  :rules="[{ required: true, message: '请输入允许下班早走时间', trigger: 'blur' }, { pattern: /^\+?[0-9][0-9]*$/, message: '必须为整数', trigger: 'blur' }]"
                  class="elastic_punch_in_box_input">
                  <el-input maxlength="4" v-model="item.leave_early_minute"
                    @input="(e) => (item.leave_early_minute = validSpace(e))"></el-input>
                </el-form-item>
                分钟内不算早退，当月
                <el-form-item :prop="'elastic_punch_in[' + index + '].leave_early_count'"
                  :rules="[{ required: true, message: '请输入允许上班早退次数', trigger: 'blur' }, { pattern: /^\+?[0-9][0-9]*$/, message: '必须为整数', trigger: 'blur' }]"
                  class="elastic_punch_in_box_input">
                  <el-input maxlength="2" v-model="item.leave_early_count"
                    @input="(e) => (item.leave_early_count = validSpace(e))"></el-input>
                </el-form-item>
                次有效
              </div>
            </div>
          </el-form-item>
        </el-form>
        <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitAddForm" v-preventReClick>保 存
        </lbButton>
      </div>
    </div>
    <lebo-dialog title="配置日期" :isShow="isShowConfigDate" width="70%" class="date_dialog" @close="isShowConfigDate = false"
      footerSlot>
      <YearCalendar :lang="'tw'" v-model="year" :activeDates.sync="activeDates" @toggleDate="toggleDate"
        prefixClass="your_customized_wrapper_class" :activeClass="activeClass"></YearCalendar>
      <span slot="footer" class="dialog-footer">
        <div class="bottom_box">
          按周配置休息日：
          <span class="btn_box" @click="clickWeek(j.id)" v-for="(j, i) in weekList" :key="i">{{ j.lable }}</span>
        </div>
        <div>
          <!-- <lbButton type="goBack" icon="back" @click="isShowConfigDate = false">返 回</lbButton> -->
          <lbButton type="default" fill icon="confirm" @click="editHoliday">保 存</lbButton>
        </div>
      </span>
    </lebo-dialog>
    <lebo-dialog width="30%" title="友情提示" :isShow="isFriendshipToSubmit" @close="isFriendshipToSubmit = false" footerSlot>
      <div style="text-align: left;text-indent: 2em; ">修改成功后，该考勤规则绑定的考勤组人员本月内的考勤统计数据将按修改后的考勤规则进行统计，您确定立即修改吗？</div>
      <div slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="isFriendshipToSubmit = false">再想一下</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="fnUpdateAttendanceRules(updateObj)">保 存</lbButton>
      </div>
    </lebo-dialog>
  </div>
</template>

<script>
import YearCalendar from 'vue-material-year-calendar'
import { addAttendanceRules, updateAttendanceRules, getAttendanceRulesDetail } from '@/api/administrative'
import { getOrganizationSeleteList } from '@/api/securityApi'
import { mapMutations } from 'vuex'
export default {
  components: { YearCalendar },
  props: ['id', 'groupCount'],
  data () {
    // 是否为数字
    var isNumber = (rule, value, callback) => {
      const isNumberReg = /^\+?[1-9][0-9]*$/
      if (isNumberReg.test(value)) {
        return callback()
      }
      callback(new Error())
    }
    return {
      title: '',
      date: new Date(),
      group_count: 0,
      updateObj: {}, // 编辑数据列表
      addForm: {
        _id: '',
        rule_name: '',
        holiday: [],
        punch_type: 1,
        // 工作时间段，若是punch_type=1为必填
        work_shift: [
          {
            to_work_time: '',
            isToWorkTimeNextDate: false,
            off_work_time: '',
            isOffWorkTimeNextDate: false
          }
        ],
        // 工作时长 punch_type=2为必填
        work_hour: 8,
        spring_type: 0, // 0-无特殊规则；1-按时段打卡；2-可弹性打卡
        // 弹性打卡
        is_spring: false,
        // 弹性打卡规则 is_spring=true时为必填
        elastic_punch_in: [
          {
            arrive_late_minute: '',
            arrive_late_count: '',
            leave_early_minute: '',
            leave_early_count: ''
          }
        ],
        // 打卡时段
        is_time_interval: false,
        // 打卡时段 is_time_interval=true时为必填
        time_interval: [
          {
            to_work_begin_time: '',
            to_work_end_time: '',
            off_work_begin_time: '',
            off_work_end_time: ''
          }
        ],
        isnottoday: false,
        organize_id: '' // 组织id
      },
      addFormRules: {
        rule_name: [
          { required: true, message: '请输入规则名称', trigger: 'blur' }
        ],
        organize_id: [
          { required: true, message: '请输选择组织', trigger: 'blur' }
        ],
        holiday: [
          { type: 'array', required: true, message: '请配置工作日', trigger: 'change' }
        ],
        work_shift: [
          { type: 'array', required: true, message: '请配置工作时段', trigger: 'change' }
        ],
        time_interval: [
          { type: 'array', required: true, message: '请配置打卡时段', trigger: 'change' }
        ],
        elastic_punch_in: [
          { type: 'array', required: true, message: '弹性打卡规则', trigger: 'change' }
        ],
        punch_type: [
          { required: true, message: '请选择工作方式', trigger: 'blur' }
        ]
      },
      // 组织下拉方法
      defaultProps: {
        children: 'children',
        label: 'name',
        value: 'id',
        // multiple: true,可以选择多个选项
        checkStrictly: true // 可以选择父类名称进行选择
      },
      isShowConfigDate: false,
      year: '',
      // 选中的休息日
      activeDates: [],
      activeClass: 'red',
      weekList: [
        { id: 1, lable: '周一' },
        { id: 2, lable: '周二' },
        { id: 3, lable: '周三' },
        { id: 4, lable: '周四' },
        { id: 5, lable: '周五' },
        { id: 6, lable: '周六' },
        { id: 0, lable: '周日' }
      ],
      // radio: 'no',
      // 友情提示对话框
      isFriendshipToSubmit: false,
      // 组织下拉列表
      organizationSeleteList: []
    }
  },
  created () {
    this.fnGetOrganizationSeleteList()
    if (this.id) {
      this.fnGetAttendanceRulesDetail()
      this.group_count = this.groupCount
      this.title = '编辑规则'
    } else {
      this.title = '新增规则'
    }
    this.date = this.$moment(this.date).format('YYYY-MM-DD ')
  },
  mounted () {
  },
  watch: {
    year (newYear, oldYear) {
      // console.log('newYear---', newYear)
    }
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    goBack () {
      this.setQueryStatus(2)
      this.$router.go(-1)
    },
    defaultForm () {
      this.setQueryStatus(2)
      this.$emit('closeDialog', false)
    },
    // 获取考勤规则详情
    async fnGetAttendanceRulesDetail () {
      const res = await getAttendanceRulesDetail({
        _id: this.id
      })
      console.log('获取考勤规则详情---', res)
      if (res && res.Code === 200) {
        var data = res.Data
        if (data.work_shift.length > 0) {
          var min = this.$moment(this.date + data.work_shift[0].to_work_time + ':00').format('x') // 开始上班时间
          data.work_shift.forEach((item, index) => {
            if (index > 0) {
              var to = this.$moment(this.date + item.to_work_time + ':00').format('x') // 上班时间
              if (min >= to) {
                item.isToWorkTimeNextDate = true
              } else {
                item.isToWorkTimeNextDate = false
              }
            }
            var off = this.$moment(this.date + item.off_work_time + ':00').format('x') // 下班时间
            if (min >= off) {
              item.isOffWorkTimeNextDate = true
            } else {
              item.isOffWorkTimeNextDate = false
            }
          })
        }
        this.year = data.holiday[0].split('-')[0]
        this.addForm._id = data._id
        this.addForm.rule_name = data.rule_name
        this.addForm.organize_id = data.organize_id
        this.addForm.holiday = data.holiday
        this.addForm.punch_type = data.punch_type
        this.addForm.work_shift = data.work_shift.length > 0 ? data.work_shift : [
          {
            to_work_time: '',
            isToWorkTimeNextDate: false,
            off_work_time: '',
            isOffWorkTimeNextDate: false
          }
        ]
        this.addForm.work_hour = data.work_hour
        this.addForm.isnottoday = data.isnottoday
        if (data.is_spring) {
          this.addForm.spring_type = 2
          this.addForm.elastic_punch_in = data.elastic_punch_in.length > 0 ? data.elastic_punch_in : [
            {
              arrive_late_minute: '',
              arrive_late_count: '',
              leave_early_minute: '',
              leave_early_count: ''
            }
          ]
        }
        if (data.is_time_interval) {
          this.addForm.spring_type = 1
          this.addForm.time_interval = data.time_interval.length ? data.time_interval : [
            {
              to_work_begin_time: '',
              to_work_end_time: '',
              off_work_begin_time: '',
              off_work_end_time: ''
            }
          ]
        }
      }
    },
    // 获取组织下拉列表
    async fnGetOrganizationSeleteList () {
      const res = await getOrganizationSeleteList()
      if (res && res.Code === 200) {
        this.organizationSeleteList = res.Data
        if (!this.id) {
          // 新增时默认选中第一项
          this.addForm.organize_id = this.organizationSeleteList[0]._id
        }
      } else {
        this.organizationSeleteList = []
      }
    },
    // 保存
    submitAddForm () {
      // console.log(this.addForm);
      // 对整个表单进行校验
      this.$refs.addFormRef.validate((valid) => {
        // 成功
        if (valid) {
          if (this.addForm.punch_type === 2 && this.addForm.is_time_interval) {
            var timeDiff = this.$moment(this.$moment(this.date + this.addForm.time_interval[0].off_work_end_time + ':00')).diff(this.$moment(this.date + this.addForm.time_interval[0].to_work_begin_time + ':00'), 'hours')
            if (timeDiff < Number(this.addForm.work_hour)) {
              this.$msg.warning('配置的打卡时间不能小于工作时间，请重新配置')
              return
            } else if (timeDiff > Number(this.addForm.work_hour)) {
              this.$msg.warning('配置的打卡时间不能大于工作时间，请重新配置')
              return
            }
          }
          var obj = {
            organize_id: this.addForm.organize_id,
            rule_name: this.addForm.rule_name,
            holiday: this.addForm.holiday,
            punch_type: this.addForm.punch_type,
            isnottoday: this.addForm.punch_type === 1 ? this.addForm.isnottoday : false,
            work_shift: this.addForm.punch_type === 1 ? this.addForm.work_shift : [],
            work_hour: this.addForm.punch_type === 2 ? this.addForm.work_hour : 0
            // is_spring: this.addForm.is_spring,
            // elastic_punch_in: this.addForm.is_spring ? this.addForm.elastic_punch_in : [],
            // is_time_interval: this.addForm.is_time_interval,
            // time_interval: this.addForm.is_time_interval ? this.addForm.time_interval : [],
          }
          if (this.addForm.spring_type === 0) {
            obj.is_spring = false
            obj.is_time_interval = false
          } else if (this.addForm.spring_type === 1) {
            obj.is_spring = false
            obj.is_time_interval = true
            obj.time_interval = this.addForm.time_interval
          } else if (this.addForm.spring_type === 2) {
            obj.is_spring = true
            obj.is_time_interval = false
            obj.elastic_punch_in = this.addForm.elastic_punch_in
          }
          if (this.id) {
            this.fnFriendshipToSubmit(obj)
          } else {
            this.fnAddAttendanceRules(obj)
          }
        } else {
          return false
        }
      })
    },
    // 友情提示用户
    fnFriendshipToSubmit (obj) {
      console.log('fnFriendshipToSubmit---1', obj)
      if (this.group_count > 0) {
        this.isFriendshipToSubmit = true
        this.updateObj = obj
      } else {
        this.fnUpdateAttendanceRules(obj)
      }
    },
    // 新增考勤规则接口
    async fnAddAttendanceRules (obj) {
      const res = await addAttendanceRules(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    },
    // 编辑考勤规则接口
    async fnUpdateAttendanceRules (obj) {
      console.log('fnUpdateAttendanceRules-----', obj)
      this.isFriendshipToSubmit = false
      // var obj = {
      //   rule_name: this.addForm.rule_name,
      //   holiday: this.addForm.holiday,
      //   punch_type: this.addForm.punch_type,
      //   isnottoday: this.addForm.punch_type === 1 ? this.addForm.isnottoday : false,
      //   work_shift: this.addForm.punch_type === 1 ? this.addForm.work_shift : [],
      //   work_hour: this.addForm.punch_type === 2 ? this.addForm.work_hour : 0,
      //   is_spring: this.addForm.is_spring,
      //   elastic_punch_in: this.addForm.is_spring ? this.addForm.elastic_punch_in : [],
      //   is_time_interval: this.addForm.is_time_interval,
      //   time_interval: this.addForm.is_time_interval ? this.addForm.time_interval : [],
      // }
      obj._id = this.id
      const res = await updateAttendanceRules(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        this.defaultForm()
      }
    },
    // 添加工作时间段
    addBillingTime (index) {
      if (this.addForm.work_shift.length >= 3) return
      // if (this.addForm.work_shift[index].to_work_time && this.addForm.work_shift[index].off_work_time) {
      if (this.addForm.work_shift[0].to_work_time && this.addForm.work_shift[this.addForm.work_shift.length - 1].off_work_time) {
        var start = this.$moment(this.date + this.addForm.work_shift[0].to_work_time + ':00').format('x')
        var end = this.$moment(this.date + this.addForm.work_shift[this.addForm.work_shift.length - 1].off_work_time + ':00').format('x')
        if (start >= end && this.addForm.work_shift[this.addForm.work_shift.length - 1].off_work_time === '11:59') return
        if (start < end && this.addForm.work_shift[this.addForm.work_shift.length - 1].off_work_time === '23:59') return
      }
      var timer = {
        to_work_time: '',
        isToWorkTimeNextDate: false,
        off_work_time: '',
        isOffWorkTimeNextDate: false
      }
      var time_interval = {
        to_work_begin_time: '',
        to_work_end_time: '',
        off_work_begin_time: '',
        off_work_end_time: ''
      }
      this.addForm.work_shift.push(timer)
      this.addForm.time_interval.push(time_interval)
      if (this.addForm.work_shift.length > 1) {
        this.addForm.is_time_interval = true
        // this.radio = 'is_time_interval';
      }
    },
    // radioChange(radio) {
    //   var arr = [];
    //   // console.log(radio);
    //   switch (radio) {
    //     case 'is_time_interval':
    //       this.addForm.is_time_interval = true;
    //       this.addForm.is_spring = false;
    //       break;
    //     case 'is_spring':
    //       this.addForm.is_spring = true;
    //       this.addForm.is_time_interval = false;
    //       arr = [];
    //       arr.push({
    //         to_work_time: this.addForm.work_shift[0].to_work_time,
    //         isToWorkTimeNextDate: this.addForm.work_shift[0].isToWorkTimeNextDate,
    //         off_work_time: this.addForm.work_shift[0].off_work_time,
    //         isOffWorkTimeNextDate: this.addForm.work_shift[0].isOffWorkTimeNextDate,
    //       });
    //       this.addForm.work_shift = arr;
    //       this.addForm.time_interval = [
    //         {
    //           to_work_begin_time: '',
    //           to_work_end_time: '',
    //           off_work_begin_time: '',
    //           off_work_end_time: '',
    //         }
    //       ]
    //       break;
    //     case 'no':
    //       this.addForm.is_time_interval = false;
    //       this.addForm.is_spring = false;
    //       arr = [];
    //       arr.push({
    //         to_work_time: this.addForm.work_shift[0].to_work_time,
    //         isToWorkTimeNextDate: this.addForm.work_shift[0].isToWorkTimeNextDate,
    //         off_work_time: this.addForm.work_shift[0].off_work_time,
    //         isOffWorkTimeNextDate: this.addForm.work_shift[0].isOffWorkTimeNextDate,
    //       });
    //       this.addForm.work_shift = arr;
    //       this.addForm.time_interval = [
    //         {
    //           to_work_begin_time: '',
    //           to_work_end_time: '',
    //           off_work_begin_time: '',
    //           off_work_end_time: '',
    //         }
    //       ]
    //       break;
    //   }
    // },
    // 删除工作时间段
    delBillingTime (index) {
      this.addForm.work_shift.splice(index, 1)
      this.addForm.time_interval.splice(index, 1)
    },
    // 当上班时间发生改变时
    toWorkTimeChange (index, time) {
      this.toWorkTimeIntervalChange(index, time) // 当上班时间发生改变时，修改打卡时间段
      this.toWorkTimeRule(index, time) // 判断选择的时间是否符合规则
      var min
      var max
      if (index > 0) {
        if (this.addForm.work_shift[0].to_work_time) {
          min = this.$moment(this.date + this.addForm.work_shift[0].to_work_time + ':00').format('x') // 开始上班时间
          max = this.$moment(this.date + time + ':00').format('x') // 当前时间
          if (max <= min) {
            this.addForm.work_shift[index].isToWorkTimeNextDate = true
            this.addForm.isnottoday = true
          } else {
            this.addForm.work_shift[index].isToWorkTimeNextDate = false
            this.addForm.isnottoday = false
          }
        }
      } else {
        if (this.addForm.work_shift[index].off_work_time) {
          max = this.$moment(this.date + this.addForm.work_shift[index].off_work_time + ':00').format('x') // 下班时间
          min = this.$moment(this.date + time + ':00').format('x') // 当前时间
          if (max <= min) {
            this.addForm.work_shift[index].isOffWorkTimeNextDate = true
            this.addForm.isnottoday = true
          } else {
            this.addForm.work_shift[index].isOffWorkTimeNextDate = false
            this.addForm.isnottoday = false
          }
        }
      }
    },
    // 当上班时间发生改变时，判断选择的时间是否符合规则
    toWorkTimeRule (index, time) {
      var max, min, t
      var start, end
      t = this.$moment(this.date + time + ':00').format('x') // 当前时间的时间戳
      start = this.$moment(this.date + this.addForm.work_shift[0].to_work_time + ':00').format('x') // 开始时间戳
      if (this.addForm.work_shift[this.addForm.work_shift.length - 1].off_work_time) {
        end = this.$moment(this.date + this.addForm.work_shift[this.addForm.work_shift.length - 1].off_work_time + ':00').format('x') // 结束时间戳
        if (start >= end) { // 跨天了
          // console.log('跨天');
          min = this.$moment(this.date + '11:59:59').format('x')
          max = this.$moment(this.date + this.addForm.work_shift[index].off_work_time + ':00').format('x')
          if (max > min) {
            if (t >= max) {
              this.addForm.work_shift[index].off_work_time = ''
              this.addForm.time_interval[index].off_work_begin_time = ''
              this.addForm.time_interval[index].off_work_end_time = ''
              this.delIndexTime(index)
            }
          } else {
            if (t <= min) {
              this.addForm.work_shift[index].off_work_time = ''
              this.addForm.time_interval[index].off_work_begin_time = ''
              this.addForm.time_interval[index].off_work_end_time = ''
              this.delIndexTime(index)
            }
          }
        } else { // 没有跨天
          // console.log(' 没有跨天');
          if (this.addForm.work_shift[index].off_work_time) {
            max = this.$moment(this.date + this.addForm.work_shift[index].off_work_time + ':00').format('x')
            if (t >= max) {
              this.addForm.work_shift[index].off_work_time = ''
              this.addForm.time_interval[index].off_work_begin_time = ''
              this.addForm.time_interval[index].off_work_end_time = ''
              this.delIndexTime(index)
            }
          }
        }
      } else {
        if (this.addForm.work_shift[index].off_work_time) {
          max = this.$moment(this.date + this.addForm.work_shift[index].off_work_time + ':00').format('x')
          if (t >= max) {
            this.addForm.work_shift[index].off_work_time = ''
            this.addForm.time_interval[index].off_work_begin_time = ''
            this.addForm.time_interval[index].off_work_end_time = ''
            this.delIndexTime(index)
          }
        }
      }
    },
    // 清除不符合规则的上下班时间、上下班打卡时间
    delIndexTime (index) {
      this.addForm.work_shift.forEach((item, i) => {
        if (i > index) {
          item.to_work_time = ''
          item.off_work_time = ''
        }
      })
      this.addForm.time_interval.forEach((item, i) => {
        if (i > index) {
          item.to_work_begin_time = ''
          item.to_work_end_time = ''
          item.off_work_begin_time = ''
          item.off_work_end_time = ''
        }
      })
    },
    // 当上班时间发生改变时，修改上班打卡时间段
    toWorkTimeIntervalChange (index, time) {
      var newDTime
      if (index > 0) { // 如果不是第一段上班时间
        var to, max
        if (this.addForm.time_interval[index - 1].off_work_end_time) {
          to = this.$moment(this.date + time + ':00').format('x') // 当前时间戳
          // console.log(';当前时间戳---*****---', to);
          max = this.$moment(this.date + this.addForm.time_interval[index - 1].off_work_end_time + ':00').format('x') // 上一段下班打卡结束时间戳
          // console.log('上一段下班打卡结束时间戳---**-max---', max);
          // console.log('上一段下班打卡结束时间戳---**-to---', to);
          if (max >= to) {
            this.addForm.time_interval[index - 1].off_work_end_time = ''
            this.addForm.time_interval[index - 1].off_work_begin_time = ''
            newDTime = this.$moment(this.date + time + ':00').subtract(1, 'h').format('HH:mm')
            const s = this.$moment(this.date + time + ':00')
            const t = this.$moment(this.date + this.addForm.work_shift[index - 1].off_work_time + ':00')
            // console.log(s.diff(t, 'hours', true));
            if (s.diff(t, 'hours', true) <= 1) {
              this.addForm.time_interval[index].to_work_begin_time = ''
            } else {
              this.addForm.time_interval[index].to_work_begin_time = newDTime
            }
          } else {
            // t 上一段下班时间
            const t = this.date + this.addForm.time_interval[index - 1].off_work_end_time + ':00'
            // d 当前时间
            const d = this.date + time + ':00'
            // console.log(this.$moment(d).diff(t));
            if (this.$moment(d).diff(t) > 7200000) { // 若 t 与 d 相差大于7200000 毫秒，则上班开始打卡时间减一个小时
              // 减一小时
              newDTime = this.$moment(this.date + time + ':00').subtract(1, 'h').format('HH:mm')
              this.addForm.time_interval[index].to_work_begin_time = newDTime
            } else if (this.$moment(d).diff(t) <= 3600000) { // 若 t 与 d 相差小于3600000 毫秒，则上班开始打卡时间为空且上一段下班结束打卡时间也为空
              this.addForm.time_interval[index].to_work_begin_time = ''
              this.addForm.time_interval[index - 1].off_work_end_time = ''
            } else { // 否则上班开始打卡时间为空
              this.addForm.time_interval[index].to_work_begin_time = ''
            }
            // newDTime = this.$moment(this.date + this.addForm.time_interval[index - 1].off_work_end_time + ':00').add(1, 'm').format('HH:mm');
            // this.addForm.time_interval[index].to_work_begin_time = newDTime;
          }
        } else {
          if (this.addForm.time_interval[index - 1].off_work_begin_time) {
            // t 上一段下班时间
            const t = this.date + this.addForm.time_interval[index - 1].off_work_begin_time + ':00'
            // d 当前时间
            const d = this.date + time + ':00'
            // console.log(this.$moment(d).diff(t));
            if (this.$moment(d).diff(t) > 7200000) { // 若 t 与 d 相差大于7200000 毫秒，则上班开始打卡时间减一个小时
              // 减一小时
              newDTime = this.$moment(this.date + time + ':00').subtract(1, 'h').format('HH:mm')
              this.addForm.time_interval[index].to_work_begin_time = newDTime
            } else if (this.$moment(d).diff(t) <= 3600000) { // 若 t 与 d 相差小于3600000 毫秒，则上班开始打卡时间为空且上一段下班结束打卡时间也为空
              this.addForm.time_interval[index].to_work_begin_time = ''
              this.addForm.time_interval[index - 1].off_work_end_time = ''
            } else { // 否则上班开始打卡时间为空
              this.addForm.time_interval[index].to_work_begin_time = ''
            }
            // newDTime = this.$moment(this.date + this.addForm.time_interval[index - 1].off_work_begin_time + ':00').add(1, 'm').format('HH:mm');
            // this.addForm.time_interval[index].to_work_begin_time = newDTime;
          } else {
            if (this.addForm.work_shift[index - 1].off_work_time) {
              // t 上一段下班时间
              const t = this.date + this.addForm.work_shift[index - 1].off_work_time + ':00'
              // d 当前时间
              const d = this.date + time + ':00'
              // console.log(this.$moment(d).diff(t));
              if (this.$moment(d).diff(t) > 7200000) { // 若 t 与 d 相差大于7200000 毫秒，则上班开始打卡时间减一个小时
                // 减一小时
                newDTime = this.$moment(this.date + time + ':00').subtract(1, 'h').format('HH:mm')
                this.addForm.time_interval[index].to_work_begin_time = newDTime
              } else if (this.$moment(d).diff(t) <= 3600000) { // 若 t 与 d 相差小于3600000 毫秒，则上班开始打卡时间为空且上一段下班结束打卡时间也为空
                this.addForm.time_interval[index].to_work_begin_time = ''
                this.addForm.time_interval[index - 1].off_work_end_time = ''
              } else { // 否则上班开始打卡时间为空
                this.addForm.time_interval[index].to_work_begin_time = ''
              }
              this.addForm.time_interval[index].to_work_end_time = time
            }
          }
        }
        this.addForm.time_interval[index].to_work_end_time = time
      } else { // 若是第一段上班时间
        // s 当天开始时间戳
        const s = this.date + '01:00:00'
        // m 当前时间戳
        const m = this.date + time + ':00'
        // console.log(this.$moment(m).diff(s));
        if (this.$moment(m).diff(s) <= 0) { // 如果在00:00-1:00之间上班,则从00:00开始打卡
          this.addForm.time_interval[index].to_work_begin_time = '00:00'
        } else { // 如果在1:00之后上班,则上班开始打卡时间减一个小时
          // 减一小时
          var newTime = this.$moment(this.date + time + ':00').subtract(1, 'h').format('HH:mm')
          this.addForm.time_interval[index].to_work_begin_time = newTime
        }
        this.addForm.time_interval[index].to_work_end_time = time
      }
    },
    // 上班时间限制
    toWorkTime (index) {
      // console.log('上班时间限制----', index);
      var m, n
      var start, end
      if (index > 0) {
        if (this.addForm.work_shift[0].to_work_time && this.addForm.work_shift[index - 1].off_work_time) {
          start = this.$moment(this.date + this.addForm.work_shift[0].to_work_time + ':00').format('x') // 开始时间戳
          end = this.$moment(this.date + this.addForm.work_shift[index - 1].off_work_time + ':00').format('x') // 结束时间戳
          if (start >= end) { // 跨天了
            n = this.$moment(this.date + this.addForm.work_shift[index - 1].off_work_time + ':00').add(1, 'm').format('HH:mm:ss') // 上一下班时间加1
            return n + '-11:58:00'
          } else { // 没有跨天
            var min = this.$moment(this.date + '11:59:59').format('x')
            if (start >= min) { // 在12点以后开始上班
              n = this.$moment(this.date + this.addForm.work_shift[index - 1].off_work_time + ':00').add(1, 'm').format('HH:mm:ss') // 上一下班时间加1
              return [n + '-23:58:00', '00:00:00-11:58:00']
            } else {
              if (this.addForm.work_shift[index - 1].off_work_time === '23:59') {
                return '23:59:59-23:59:59'
              } else {
                n = this.$moment(this.date + this.addForm.work_shift[index - 1].off_work_time + ':00').add(1, 'm').format('HH:mm:ss') // 上一下班时间加1
                return n + '-23:58:00'
              }
            }
          }
        }
      }
    },
    // 当下班时间发生改变时
    offWorkTimeChange (index, time) {
      if (this.addForm.work_shift[0].to_work_time) {
        var min = this.$moment(this.date + this.addForm.work_shift[0].to_work_time + ':00').format('x')
        var end = this.$moment(this.date + time + ':00').format('x')
        var max = this.$moment(this.date + '11:59:59').format('x')
        if (min >= max) {
          if (time === '11:59' && this.addForm.work_shift[index + 1]) {
            this.delBillingTime(index + 1)
          }
        }
        if (min >= end) {
          this.addForm.work_shift[index].isOffWorkTimeNextDate = true
          this.addForm.isnottoday = true
        } else {
          this.addForm.work_shift[index].isOffWorkTimeNextDate = false
          this.addForm.isnottoday = false
        }
      }
      this.offWorkTimeIntervalChange(index, time)
      this.offWorkTimeRule(index, time)
    },
    // 当下班时间发生改变时，判断选择的时间是否符合规则
    offWorkTimeRule (index, time) {
      var max, min, t
      min = this.$moment(this.date + this.addForm.work_shift[0].to_work_time + ':00').format('x') // 开始上班的时间戳
      t = this.$moment(this.date + time + ':00').format('x') // 当前时间的时间戳
      if (this.addForm.work_shift[index + 1]) {
        if (t > min) {
          max = this.$moment(this.date + this.addForm.work_shift[index + 1].to_work_time + ':00').format('x')
          if (t >= max) {
            this.delIndexTime(index)
          }
        } else {
          max = this.$moment(this.date + '11:59:59').format('x')
          if (t <= max) {
            this.delIndexTime(index)
          }
        }
      }
    },
    // 当下班时间发生改变时，修改下班打卡时间段
    offWorkTimeIntervalChange (index, time) {
      var s, d, newTime, max, start
      if (this.addForm.work_shift[0].to_work_time) {
        start = this.$moment(this.date + this.addForm.work_shift[0].to_work_time + ':00').format('x')
        max = this.$moment(this.date + '11:59:59').format('x')
        if (start >= max) { // 跨天
          // // s 当天11点时间戳
          s = this.date + '11:59:59'
          //  // d 当前时间戳
          d = this.date + time + ':00'
          // console.log('offWorkTimeIntervalChange---', this.$moment(s).diff(d));
          if (this.$moment(s).diff(d) >= 0 && this.$moment(s).diff(d) <= 3600000) {
            this.addForm.time_interval[index].off_work_end_time = '11:59'
          } else {
            // 加一小时
            newTime = this.$moment(this.date + time + ':00').add(1, 'h').format('HH:mm')
            this.addForm.time_interval[index].off_work_end_time = newTime
          }
        } else {
          // s 当天结束时间戳
          s = this.date + '23:00:00'
          // d 当前时间戳
          d = this.date + time + ':00'
          if (this.$moment(s).diff(d) <= 0) {
            // console.log('sdfas------当天结束时间戳----fsdfs');
            this.addForm.time_interval[index].off_work_end_time = '23:59'
          } else {
            // 加一小时
            newTime = this.$moment(this.date + time + ':00').add(1, 'h').format('HH:mm')
            this.addForm.time_interval[index].off_work_end_time = newTime
            if (this.addForm.time_interval[index + 1]) {
              var m = this.date + newTime + ':00'
              var t
              if (this.addForm.time_interval[index + 1].to_work_end_time) {
                // console.log('sdfas------=====----fsdfs');
                t = this.date + this.addForm.time_interval[index + 1].to_work_end_time + ':00'
                if (this.$moment(t).diff(m) <= 0) {
                  this.addForm.time_interval[index + 1].to_work_end_time = ''
                  this.addForm.time_interval[index].off_work_end_time = ''
                }
                if (this.addForm.time_interval[index + 1].to_work_begin_time) {
                  t = this.date + this.addForm.time_interval[index + 1].to_work_begin_time + ':00'
                  if (this.$moment(t).diff(m) <= 0) {
                    this.addForm.time_interval[index + 1].to_work_begin_time = ''
                  }
                }
              } else {
                if (this.addForm.time_interval[index + 1].to_work_begin_time) {
                  t = this.date + this.addForm.time_interval[index + 1].to_work_begin_time + ':00'
                  if (this.$moment(t).diff(m) <= 0) {
                    this.addForm.time_interval[index + 1].to_work_begin_time = ''
                  }
                }
              }
            }
          }
        }
      }
      this.addForm.time_interval[index].off_work_begin_time = time
    },
    // 下班时间限制
    offWorkTime (index) {
      // console.log('下班时间限制----', index);
      var m, n
      var min
      var start, end
      if (index > 0) {
        if (this.addForm.work_shift[0].to_work_time && this.addForm.work_shift[index].to_work_time) {
          start = this.$moment(this.date + this.addForm.work_shift[0].to_work_time + ':00').format('x') // 开始时间戳
          min = this.$moment(this.date + '11:59:59').format('x') // 12点的时间戳
          end = this.$moment(this.date + this.addForm.work_shift[index].to_work_time + ':00').format('x') // 结束时间戳
          if (start >= min) {
            if (end >= start) {
              m = this.$moment(this.date + this.addForm.work_shift[0].to_work_time + ':00').subtract(1, 'm').format('HH:mm:ss') // 开始上班时间减一
              n = this.$moment(this.date + this.addForm.work_shift[index].to_work_time + ':00').add(1, 'm').format('HH:mm:ss') // 该上班时间加1
              return ['00:00:00-' + m, n + '-23:59:59']
            } else {
              n = this.$moment(this.date + this.addForm.work_shift[index].to_work_time + ':00').add(1, 'm').format('HH:mm:ss') // 该上班时间加1
              return n + '-11:59:59'
            }
          } else {
            if (this.addForm.work_shift[index].to_work_time === '23:59') {
              return '23:59:59-23:59:59'
            } else {
              n = this.$moment(this.date + this.addForm.work_shift[index].to_work_time + ':00').add(1, 'm').format('HH:mm:ss') // 该上班时间加1
              return n + '-23:59:59'
            }
          }
        }
      } else {
        if (this.addForm.work_shift[index].to_work_time) {
          if (this.addForm.work_shift[index].to_work_time !== '00:00') { // 如果开始上班时间不为00:00
            min = this.$moment(this.date + '11:59:59').format('x')
            var t = this.$moment(this.date + this.addForm.work_shift[index].to_work_time + ':00').format('x')
            m = this.$moment(this.date + this.addForm.work_shift[index].to_work_time + ':00').subtract(1, 'm').format('HH:mm:ss') // 开始上班时间减一
            n = this.$moment(this.date + this.addForm.work_shift[index].to_work_time + ':00').add(1, 'm').format('HH:mm:ss') // 开始上班时间加1
            if (t >= min) {
              if (this.addForm.work_shift[index].to_work_time !== '23:59') {
                return ['00:00:00-11:59:59', n + '-23:59:59']
              } else {
                return '00:00:00-11:59:59'
              }
            } else {
              return n + '-23:59:59'
            }
          } else { // 如果开始上班时间为00:00
            n = this.$moment(this.date + this.addForm.work_shift[index].to_work_time + ':00').add(1, 'm').format('HH:mm:ss') // 开始上班时间加1
            return n + '-23:59:59'
          }
        }
      }
    },
    // 下班卡开始时段限制
    offWorkBeginTime (index) {
      if (this.addForm.punch_type === 1) { // 固定班制
        // 1、判断是否选择了上班时间、下班时间
        if (this.addForm.work_shift[index].to_work_time && this.addForm.work_shift[index].off_work_time) {
          var m, to, off
          // 上班时间的时间戳   date表示当天
          to = this.$moment(this.date + this.addForm.work_shift[0].to_work_time + ':00').format('x')
          if (this.addForm.work_shift.length > 1) { // 多段上班时间
            if (this.addForm.work_shift[this.addForm.work_shift.length - 1].off_work_time) {
              // 最后下班时间的时间戳
              off = this.$moment(this.date + this.addForm.work_shift[this.addForm.work_shift.length - 1].off_work_time + ':00').format('x')
              if (off <= to) { // 若跨天了
                if (index === this.addForm.work_shift.length - 1) { // 若是最后一个下班打卡时间，则 下班时间 ~ 11:59
                  return this.addForm.work_shift[index].off_work_time + ':00-11:59:59'
                } else { // 若不是最后一个下班打卡时间，则 下班时间 ~ 下一上班时间-1
                  if (this.addForm.work_shift[index + 1].to_work_time !== '00:00') {
                    if (this.addForm.time_interval[index + 1].to_work_begin_time) {
                      // 当前下班时间
                      const s = this.$moment(this.date + this.addForm.work_shift[index].off_work_time + ':00').format('x')
                      // 下一段的上班时间
                      const d = this.$moment(this.date + this.addForm.time_interval[index + 1].to_work_begin_time + ':00').format('x')
                      // 减一分钟
                      m = this.$moment(this.date + this.addForm.time_interval[index + 1].to_work_begin_time + ':00').subtract(1, 'm').format('HH:mm:ss')
                      if (s >= d) { // 若当前下班时间大于 下一段的上班时间，则返回数据形式的   [ 下班时间 ~ 下一上班时间-1]
                        return [this.addForm.work_shift[index].off_work_time + ':00-23:59:59', '00:00:00-' + m]
                      } else { // 否则返回  下班时间 ~ 下一上班时间-1
                        return this.addForm.work_shift[index].off_work_time + ':00-' + m
                      }
                    } else {
                      // 当前下班时间
                      const s = this.$moment(this.date + this.addForm.work_shift[index].off_work_time + ':00').format('x')
                      // 下一段的上班时间
                      const d = this.$moment(this.date + this.addForm.work_shift[index + 1].to_work_time + ':00').format('x')
                      // 减一分钟
                      m = this.$moment(this.date + this.addForm.work_shift[index + 1].to_work_time + ':00').subtract(1, 'm').format('HH:mm:ss')
                      if (s >= d) { // 若当前下班时间大于 下一段的上班时间，则返回数据形式的   [ 下班时间 ~ 下一上班时间-1]
                        return [this.addForm.work_shift[index].off_work_time + ':00-23:59:59', '00:00:00-' + m]
                      } else { // 否则返回  下班时间 ~ 下一上班时间-1
                        return this.addForm.work_shift[index].off_work_time + ':00-' + m
                      }
                    }
                  } else {
                    return this.addForm.work_shift[index].off_work_time + ':00-23:59:59'
                  }
                }
              } else { // 没有跨天
                if (index === this.addForm.work_shift.length - 1) { // 若是最后一个下班打卡时间，则 下班时间 ~ 23:59
                  return this.addForm.work_shift[index].off_work_time + ':00-23:59:59'
                } else { // 若不是最后一个下班打卡时间，则 下班时间 ~ 下一上班时间-1
                  if (this.addForm.time_interval[index + 1].to_work_begin_time) {
                    m = this.$moment(this.date + this.addForm.time_interval[index + 1].to_work_begin_time + ':00').subtract(1, 'm').format('HH:mm:ss')
                    return this.addForm.work_shift[index].off_work_time + ':00-' + m
                  } else {
                    if (this.addForm.work_shift[index + 1].to_work_time) {
                      m = this.$moment(this.date + this.addForm.work_shift[index + 1].to_work_time + ':00').subtract(1, 'm').format('HH:mm:ss')
                      return this.addForm.work_shift[index].off_work_time + ':00-' + m
                    } else {
                      return this.addForm.work_shift[index].off_work_time + ':00-23:59:59'
                    }
                  }
                }
              }
            } else {
              if (this.addForm.time_interval[index + 1].to_work_begin_time) {
                m = this.$moment(this.date + this.addForm.time_interval[index + 1].to_work_begin_time + ':00').subtract(1, 'm').format('HH:mm:ss')
                return this.addForm.work_shift[index].off_work_time + ':00-' + m
              } else {
                if (this.addForm.work_shift[index + 1].to_work_time) {
                  var n = this.$moment(this.date + this.addForm.work_shift[index + 1].to_work_time + ':00').subtract(1, 'm').format('HH:mm:ss')
                  return this.addForm.work_shift[index].off_work_time + ':00-' + n
                } else {
                  off = this.$moment(this.date + this.addForm.work_shift[index].off_work_time + ':00').format('x')
                  if (off <= to) { // 若跨天了
                    return this.addForm.work_shift[index].off_work_time + ':00-11:59:59'
                  } else { // 没有跨天
                    return this.addForm.work_shift[index].off_work_time + ':00-23:59:59'
                  }
                }
              }
            }
          } else { // 一段上班时间
            // 下班时间的时间戳
            off = this.$moment(this.date + this.addForm.work_shift[index].off_work_time + ':00').format('x')
            if (off <= to) { // 若跨天了
              return this.addForm.work_shift[index].off_work_time + ':00-11:59:59'
            } else { // 没有跨天
              return this.addForm.work_shift[index].off_work_time + ':00-23:59:59'
            }
          }
        }
      } else if (this.addForm.punch_type === 2) { // 自由班制
        var t
        if (this.addForm.time_interval[index].to_work_end_time) {
          t = this.$moment(this.date + this.addForm.time_interval[index].to_work_end_time + ':00').add(1, 'm').format('HH:mm:ss')
          return t + '-23:59:59'
        } else {
          if (this.addForm.time_interval[index].to_work_begin_time) {
            t = this.$moment(this.date + this.addForm.time_interval[index].to_work_begin_time + ':00').add(1, 'm').format('HH:mm:ss')
            return t + '-23:59:59'
          }
        }
      }
    },
    // 下班卡开始时段发生改变时触发
    offWorkBeginTimeChange (index, time) {
      if (this.addForm.time_interval[index].off_work_end_time) {
        var start = this.$moment(this.date + time + ':00').format('x')
        var end = this.$moment(this.date + this.addForm.time_interval[index].off_work_end_time + ':00').format('x')
        if (start > end) {
          this.addForm.time_interval[index].off_work_end_time = ''
        }
      }
    },
    // 上班卡开始时段发生改变时触发
    toWorkBeginTimeChange (index, time) {
      if (this.addForm.time_interval[index].to_work_end_time) {
        var start = this.$moment(this.date + time + ':00').format('x')
        var end = this.$moment(this.date + this.addForm.time_interval[index].to_work_end_time + ':00').format('x')
        if (start > end) {
          this.addForm.time_interval[index].to_work_end_time = ''
        }
      }
    },
    // 下班卡结束时段限制
    offWorkEndTime (index) {
      if (this.addForm.punch_type === 1) { // 固定班制
        // console.log('下班卡时段限制-----', index);
        // console.log('上班时间----', this.addForm.work_shift[index].to_work_time);
        // console.log('下班时间----', this.addForm.work_shift[index].off_work_time);
        // 1、判断是否选择了上班时间、下班时间
        if (this.addForm.work_shift[index].to_work_time && this.addForm.work_shift[index].off_work_time) {
          var m, to, off
          // 上班时间的时间戳   date表示当天
          to = this.$moment(this.date + this.addForm.work_shift[0].to_work_time + ':00').format('x')
          // 下班时间 +1
          var t
          // var t = this.$moment(this.date + this.addForm.time_interval[index].off_work_begin_time + ':00').add(1, 'm').format('HH:mm:ss');
          if (this.addForm.time_interval[index].off_work_begin_time) {
            t = this.addForm.time_interval[index].off_work_begin_time + ':00'
          } else {
            t = this.addForm.work_shift[index].off_work_time + ':00'
          }
          if (this.addForm.work_shift.length > 1) { // 多段上班时间
            if (this.addForm.work_shift[this.addForm.work_shift.length - 1].off_work_time) {
              // 下班时间的时间戳
              off = this.$moment(this.date + this.addForm.work_shift[this.addForm.work_shift.length - 1].off_work_time + ':00').format('x')
              if (off <= to) { // 若跨天了
                if (index === this.addForm.work_shift.length - 1) { // 若是最后一个下班打卡时间，则 下班时间 +1 ~ 11:59
                  return t + '-11:59:59'
                } else { // 若不是最后一个下班打卡时间，则 下班时间 +1 ~ 下一上班时间-1
                  if (this.addForm.work_shift[index + 1].to_work_time !== '00:00') {
                    // 当前下班时间
                    const s = this.$moment(this.date + this.addForm.work_shift[index].off_work_time + ':00').format('x')
                    // 下一段的上班时间
                    const d = this.$moment(this.date + this.addForm.work_shift[index + 1].to_work_time + ':00').format('x')
                    // 减一分钟
                    if (this.addForm.time_interval[index + 1].to_work_begin_time) {
                      m = this.$moment(this.date + this.addForm.time_interval[index + 1].to_work_begin_time + ':00').subtract(1, 'm').format('HH:mm:ss')
                    } else {
                      m = this.$moment(this.date + this.addForm.work_shift[index + 1].to_work_time + ':00').subtract(1, 'm').format('HH:mm:ss')
                    }
                    if (s >= d) { // 若当前下班时间大于 下一段的上班时间，则返回数据形式的   [ 下班时间 +1~ 下一上班时间-1]
                      return [t + '-23:59:59', '00:00:00-' + m]
                    } else { // 否则返回  下班时间 +1 ~ 下一上班时间-1
                      return t + '-' + m
                    }
                  } else {
                    return t + '-23:59:59'
                  }
                }
              } else { // 没有跨天
                if (index === this.addForm.work_shift.length - 1) { // 若是最后一个下班打卡时间，则 下班时间 +1 ~ 23:59
                  return t + '-23:59:59'
                } else { // 若不是最后一个下班打卡时间，则 下班时间 +1 ~ 下一上班时间-1
                  if (this.addForm.time_interval[index + 1].to_work_begin_time) {
                    m = this.$moment(this.date + this.addForm.time_interval[index + 1].to_work_begin_time + ':00').subtract(1, 'm').format('HH:mm:ss')
                  } else {
                    if (this.addForm.work_shift[index + 1].to_work_time) {
                      m = this.$moment(this.date + this.addForm.work_shift[index + 1].to_work_time + ':00').subtract(1, 'm').format('HH:mm:ss')
                    } else {
                      m = '23:59:59'
                    }
                  }
                  return t + '-' + m
                }
              }
            } else {
              // console.log('下班卡结束时段限制--to_work_time--', this.addForm.work_shift[index + 1].to_work_time);
              if (this.addForm.work_shift[index + 1].to_work_time) {
                var n
                if (this.addForm.time_interval[index + 1].to_work_begin_time) {
                  n = this.$moment(this.date + this.addForm.time_interval[index + 1].to_work_begin_time + ':00').subtract(1, 'm').format('HH:mm:ss')
                  return t + '-' + n
                } else {
                  n = this.$moment(this.date + this.addForm.work_shift[index + 1].to_work_time + ':00').subtract(1, 'm').format('HH:mm:ss')
                  return t + '-' + n
                }
              } else {
                // 下班时间的时间戳
                off = this.$moment(this.date + this.addForm.work_shift[index].off_work_time + ':00').format('x')
                if (off <= to) { // 若跨天了, 则 下班时间 ~ 11:59
                  return t + ':00-11:59:59'
                } else { // 没有跨天
                  return t + '-23:59:59'
                }
              }
            }
          } else { // 一段上班时间
            // 下班时间的时间戳
            off = this.$moment(this.date + this.addForm.work_shift[index].off_work_time + ':00').format('x')
            if (off <= to) { // 若跨天了, 则 下班时间 ~ 11:59
              return t + ':00-11:59:59'
            } else { // 没有跨天
              return t + '-23:59:59'
            }
          }
        }
      } else if (this.addForm.punch_type === 2) { // 自由班制
        var h
        if (this.addForm.time_interval[index].off_work_begin_time) {
          h = this.$moment(this.date + this.addForm.time_interval[index].off_work_begin_time + ':00').add(1, 'm').format('HH:mm:ss')
          return h + '-23:59:59'
        } else {
          if (this.addForm.time_interval[index].to_work_end_time) {
            h = this.$moment(this.date + this.addForm.time_interval[index].to_work_end_time + ':00').add(1, 'm').format('HH:mm:ss')
            return h + '-23:59:59'
          } else {
            if (this.addForm.time_interval[index].to_work_begin_time) {
              h = this.$moment(this.date + this.addForm.time_interval[index].to_work_begin_time + ':00').add(1, 'm').format('HH:mm:ss')
              return h + '-23:59:59'
            }
          }
        }
      }
    },
    // 上班卡开始时间限制
    toWorkBeginTime (index) {
      if (this.addForm.punch_type === 1) { // 固定班制
        // 1、判断是否选择了上班时间、下班时间
        if (this.addForm.work_shift[index].to_work_time) {
          var m, to, off
          // 上班时间的时间戳   date表示当天
          to = this.$moment(this.date + this.addForm.work_shift[0].to_work_time + ':00').format('x')
          // 上班时间 -1
          var t = this.addForm.work_shift[index].to_work_time + ':00'
          // 2、判断是否有多段打卡时间
          if (this.addForm.work_shift.length > 1) { // 多段打卡时间
            // console.log(' 上班卡开始时间限制--off_work_time--', this.addForm.work_shift[this.addForm.work_shift.length - 1].off_work_time);
            // 下班时间的时间戳
            if (this.addForm.work_shift[this.addForm.work_shift.length - 1].off_work_time) {
              off = this.$moment(this.date + this.addForm.work_shift[this.addForm.work_shift.length - 1].off_work_time + ':00').format('x')
              //  2、判断上下班时间是否跨天了, 若下班时间小于上班时间，则跨天了
              if (off <= to) { // 若跨天了
                if (index === 0) { // 若是第一段，11:59 ~ 上班时间 -1
                  const a = this.$moment(this.date + this.addForm.work_shift[index].to_work_time + ':00').format('x') // 当前上班时间戳
                  const b = this.$moment(this.date + '11:59:59').format('x') // 11:59时间戳
                  if (a <= b) {
                    return ['11:59:59-23:59:59', '00:00:00-' + t]
                  } else {
                    return '11:59:59-' + t
                  }
                } else { // 若不是第一段， 上一个下班时间+1 ~ 上班时间 -1
                  const s = this.$moment(this.date + this.addForm.work_shift[index].to_work_time + ':00').format('x') // 当前上班时间
                  const d = this.$moment(this.date + this.addForm.work_shift[index - 1].off_work_time + ':00').format('x') // 上一段的下班时间
                  if (this.addForm.time_interval[index - 1].off_work_end_time) {
                    if (d >= s) { // 若当前上班时间小于 上一段的下班时间，则返回数据形式的   [ 上- 下班时间 +1~ 上班时间-1]
                      if (this.addForm.work_shift[index].to_work_time !== '00:00') {
                        m = this.$moment(this.date + this.addForm.time_interval[index - 1].off_work_end_time + ':00').add(1, 'm').format('HH:mm:ss')
                        const min = this.$moment(this.date + this.addForm.time_interval[index - 1].off_work_end_time + ':00').format('x')
                        const max = this.$moment(this.date + '11:59:59').format('x')
                        if (min >= max) {
                          return [m + '-23:59:59', '00:00:00-' + t]
                        } else {
                          return m + '-' + t
                        }
                      } else {
                        return '00:00:00-00:00:00'
                      }
                    } else {
                      m = this.$moment(this.date + this.addForm.time_interval[index - 1].off_work_end_time + ':00').add(1, 'm').format('HH:mm:ss')
                      return m + '-' + t
                    }
                  } else {
                    if (d >= s) { // 若当前上班时间小于 上一段的下班时间，则返回数据形式的   [ 上- 下班时间 +1~ 上班时间-1]
                      if (this.addForm.work_shift[index].to_work_time !== '00:00') {
                        m = this.$moment(this.date + this.addForm.work_shift[index - 1].off_work_time + ':00').add(1, 'm').format('HH:mm:ss')
                        const min = this.$moment(this.date + this.addForm.work_shift[index - 1].off_work_time + ':00').format('x')
                        const max = this.$moment(this.date + '11:59:59').format('x')
                        if (min >= max) {
                          return [m + '-23:59:59', '00:00:00-' + t]
                        } else {
                          return m + '-' + t
                        }
                      } else {
                        return '00:00:00-00:00:00'
                      }
                    } else {
                      m = this.$moment(this.date + this.addForm.work_shift[index - 1].off_work_time + ':00').add(1, 'm').format('HH:mm:ss')
                      return m + '-' + t
                    }
                  }
                }
              } else { // 若没有跨天
                if (index === 0) { // 如果是第一个打卡时间，则  00:00-上班时间-1
                  if (this.addForm.work_shift[index].to_work_time !== '00:00') {
                    return '00:00:00-' + t
                  } else {
                    return '00:00:00-00:00:00'
                  }
                } else { // 若不是第一个打卡时间，则 上一个下班时间+1 ~ 上班时间 -1
                  if (this.addForm.time_interval[index - 1].off_work_end_time) {
                    m = this.$moment(this.date + this.addForm.time_interval[index - 1].off_work_end_time + ':00').add(1, 'm').format('HH:mm:ss')
                    return m + '-' + t
                  } else {
                    m = this.$moment(this.date + this.addForm.work_shift[index - 1].off_work_time + ':00').add(1, 'm').format('HH:mm:ss')
                    return m + '-' + t
                  }
                }
              }
            } else {
              if (index > 0) {
                if (this.addForm.time_interval[index - 1].off_work_end_time) {
                  // console.log('off_work_end_time----', this.addForm.time_interval[index - 1].off_work_end_time);
                  m = this.$moment(this.date + this.addForm.time_interval[index - 1].off_work_end_time + ':00').add(1, 'm').format('HH:mm:ss')
                  return m + '-' + t
                } else {
                  if (this.addForm.time_interval[index - 1].off_work_begin_time) {
                    // console.log('off_work_begin_time----', this.addForm.time_interval[index - 1].off_work_begin_time);
                    m = this.$moment(this.date + this.addForm.time_interval[index - 1].off_work_begin_time + ':00').add(1, 'm').format('HH:mm:ss')
                    return m + '-' + t
                  } else {
                    if (this.addForm.work_shift[index - 1].off_work_time) {
                      // console.log("off_work_time---***", this.addForm.work_shift[index - 1].off_work_time);
                      m = this.$moment(this.date + this.addForm.work_shift[index - 1].off_work_time + ':00').add(1, 'm').format('HH:mm:ss')
                      return m + '-' + t
                    }
                  }
                }
              } else {
                off = this.$moment(this.date + this.addForm.work_shift[index].off_work_time + ':00').format('x') // 下班时间的时间戳
                //  2、判断上下班时间是否跨天了, 若下班时间小于上班时间，则跨天了
                if (off <= to) { // 若跨天了  从 11:59 ~ 上班时间 -1
                  const a = this.$moment(this.date + this.addForm.work_shift[index].to_work_time + ':00').format('x') // 当前上班时间戳
                  const b = this.$moment(this.date + '11:59:59').format('x') // 11:59时间戳
                  if (a <= b) {
                    return ['11:59:59-23:59:59', '00:00:00-' + t]
                  } else {
                    return '11:59:59-' + t
                  }
                } else { // 若没有跨天 00:00 ~ 上班时间-1
                  if (this.addForm.work_shift[index].to_work_time === '00:00') {
                    return '00:00:00-00:00:00'
                  } else {
                    m = this.$moment(this.date + this.addForm.work_shift[index].to_work_time + ':00').subtract(1, 'm').format('HH:mm:ss')
                    return '00:00:00-' + m
                  }
                }
              }
            }
          } else { // 只有一段打卡时间
            off = this.$moment(this.date + this.addForm.work_shift[index].off_work_time + ':00').format('x') // 下班时间的时间戳
            //  2、判断上下班时间是否跨天了, 若下班时间小于上班时间，则跨天了
            if (off <= to) { // 若跨天了  从 11:59 ~ 上班时间 -1
              const a = this.$moment(this.date + this.addForm.work_shift[index].to_work_time + ':00').format('x') // 当前上班时间戳
              const b = this.$moment(this.date + '11:59:59').format('x') // 11:59时间戳
              if (a <= b) {
                return ['11:59:59-23:59:59', '00:00:00-' + t]
              } else {
                return '11:59:59-' + t
              }
            } else { // 若没有跨天 00:00 ~ 上班时间-1
              if (this.addForm.work_shift[index].to_work_time === '00:00') {
                return '00:00:00-00:00:00'
              } else {
                m = this.$moment(this.date + this.addForm.work_shift[index].to_work_time + ':00').subtract(1, 'm').format('HH:mm:ss')
                return '00:00:00-' + m
              }
            }
          }
        }
      }
    },
    // 上班卡结束时间限制
    toWorkEndTime (index) {
      if (this.addForm.punch_type === 1) { // 固定班制
        // 1、判断是否选择了上班时间、下班时间
        if (this.addForm.work_shift[index].to_work_time) {
          if (this.addForm.work_shift[index].to_work_time === '00:00') {
            return '00:00:00-00:00:00'
          } else {
            if (this.addForm.time_interval[index].to_work_begin_time) {
              if (this.addForm.time_interval[index].to_work_begin_time === this.addForm.work_shift[index].to_work_time) {
                return this.addForm.work_shift[index].to_work_time + ':00' + '-' + this.addForm.work_shift[index].to_work_time + ':00'
              } else {
                var t = this.$moment(this.date + this.addForm.time_interval[index].to_work_begin_time + ':00').add(1, 'm').format('HH:mm:ss')
                // 从上班开始时间，到上班时间
                return t + '-' + this.addForm.work_shift[index].to_work_time + ':00'
              }
            } else {
              return this.addForm.work_shift[index].to_work_time + ':00-' + this.addForm.work_shift[index].off_work_time + ':00'
            }
          }
        }
      } else if (this.addForm.punch_type === 2) { // 自由班制
        if (this.addForm.time_interval[index].to_work_begin_time) {
          const t = this.$moment(this.date + this.addForm.time_interval[index].to_work_begin_time + ':00').add(1, 'm').format('HH:mm:ss')
          return t + '-23:59:59'
        }
      }
    },
    // 打开配置日期对话框
    openConfigDate () {
      this.isShowConfigDate = true
      this.year = this.year ? (this.title === '编辑规则' ? this.year : new Date().getFullYear()) : new Date().getFullYear()
      this.activeDates = []
      if (this.addForm.holiday.length > 0) {
        this.addForm.holiday.forEach(item => {
          var obj = {
            date: item,
            className: 'red'
          }
          this.activeDates.push(obj)
        })
      } else {
        this.addWeekendCurrentYear()
      }
    },
    // 选中日期中的周末
    addWeekendCurrentYear () {
      var theDate = this.$moment(`${this.year}-01-01`)
      var isActiveDateUsingString = this.activeDates.length && typeof this.activeDates[0] === 'string'
      while (theDate.diff(this.$moment(`${this.year}-12-31`), 'days') <= 0) {
        if (theDate.day() === 6 || theDate.day() === 0) {
          var oDate = isActiveDateUsingString ? theDate.format('YYYY-MM-DD') : { date: theDate.format('YYYY-MM-DD') }
          oDate.className = 'red'
          this.activeDates.push(oDate)
        }
        theDate = theDate.add(1, 'day')
      }
    },
    clickWeek (day) {
      var oDate
      var theDate = this.$moment(`${this.year}-01-01`)
      var isActiveDateUsingString = this.activeDates.length && typeof this.activeDates[0] === 'string'
      while (theDate.diff(this.$moment(`${this.year}-12-31`), 'days') <= 0) {
        if (theDate.day() === day) {
          var index = this.activeDates.findIndex(item => item.date === theDate.format('YYYY-MM-DD'))
          if (index < 0) {
            oDate = isActiveDateUsingString ? theDate.format('YYYY-MM-DD') : { date: theDate.format('YYYY-MM-DD') }
            oDate.className = 'red'
            this.activeDates.push(oDate)
          } else {
            this.activeDates = this.activeDates.filter(item => item.date !== theDate.format('YYYY-MM-DD'))
          }
        }
        theDate = theDate.add(1, 'day')
      }
    },
    // 筛选出不是当前年份的数据
    // removeWeekendCurrentYear() {
    //   this.activeDates = this.activeDates.filter(oDate => {
    //     var date = typeof oDate === 'string' ? oDate : oDate.date
    //     var day = this.$moment(date).day()
    //     var isCurrentYear = this.$moment(date).year() === this.year
    //     var isWeekend = [6, 0].includes(day)
    //     return !(isCurrentYear && isWeekend)
    //   })
    // },
    // 判断当前选中年份是否已经处理过休息日
    // isWeekendCurrentYear() {
    //   var flag = this.activeDates.every(item => {
    //     return this.$moment(item.date).year() !== this.year
    //   })
    //   return flag
    // },
    toggleDate (dateInfo) {
      // console.log(dateInfo);
      // console.log(this.activeDates);
    },
    // 保存配置休息日
    editHoliday () {
      this.addForm.holiday = []
      this.activeDates.forEach(item => {
        this.addForm.holiday.push(item.date)
      })
      // console.log(this.addForm.holiday);
      this.isShowConfigDate = false
    },
    // 打卡方式改变时触发
    punchTypeChange (value) {
      if (value === 2) {
        this.addForm.is_spring = false
        this.addForm.elastic_punch_in = [
          {
            arrive_late_minute: '',
            arrive_late_count: '',
            leave_early_minute: '',
            leave_early_count: ''
          }
        ]
        this.addForm.time_interval = [
          {
            to_work_begin_time: '',
            to_work_end_time: '',
            off_work_begin_time: '',
            off_work_end_time: ''
          }
        ]
      } else if (value === 1) {
        this.addForm.work_shift = [
          {
            to_work_time: '',
            off_work_time: ''
          }
        ]
      }
    },
    // 打卡时间固定班制 打卡规则切换时出发
    springTypeChange (value) {
      if (this.addForm.work_shift.length === 1) {
        this.delBillingTime(1)
      } else {
        this.delBillingTime(1)
        this.delBillingTime(1)
      }
    },
    selectOrganize () {
      console.log('this.addForm.organize_id', this.addForm.organize_id)
    }
  }
}
</script>

<style scoped lang="less">
.content_box {

  // margin-top: 15px;
  // max-height: 710px;
  // overflow-y: auto;
  .demo-ruleForm {
    max-height: 650px;
    overflow-y: auto;

    .el-select {
      width: 100%;
    }

    .punchType {
      width: 100%;

      /deep/ .el-input {
        width: 100%;
      }

      /deep/ .el-select {
        width: 42.3%;
      }
    }
  }

  /deep/ .el-input {
    width: 86%;
  }

  .el-radio-group {
    margin-left: 15px;
  }

  .work_shift_box {
    height: 65px;
    display: flex;

    .el-form-item {
      margin: 0 12px;
      text-align: left;
      width: 35%;

      .el-input {
        width: 100%;
      }

    }
  }

  .iconButton {
    width: 25px !important;

    .el-button {
      padding: 8px !important;
    }

    &:nth-child(1) {
      margin-right: 1%;
    }
  }

  .time_interval_box {
    .item_box {
      height: 65px;
      display: flex;

      >span {
        margin: 0 10px;
      }

      >div {
        span {
          width: 80px;
          text-align: left;
          display: inline-block;
          // margin-right: 20px;
        }
      }

      .time_interva_input {
        width: 33%;

        .el-input {
          width: 100%;
        }
      }
    }
  }

  .elastic_punch_in_box {
    height: 65px;
    display: flex;

    .elastic_punch_in_box_input {
      width: 31%;
      margin: 0 10px;

      /deep/.el-input {
        width: 100%;
      }
    }

  }

  .work_hour_box {
    height: 65px;
    display: flex;
    width: 85.5%;

    >div {
      width: 70px;
    }

    .el-input {
      width: 100%;
    }
  }
}

.date_dialog {
  /deep/ .el-dialog__body {
    padding: 5px 25px;
  }

  /deep/ .el-dialog {
    margin-top: 5vh !important;
    margin: 0 auto 0px;
  }

  /deep/ .dialog-footer {
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bottom_box {
      display: flex;
      align-items: center;

      .btn_box {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 10px;
        border: 1px solid #666;
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }
}

.el-picker-panel {
  /deep/ .el-scrollbar {
    /deep/ .el-scrollbar__wrap {
      margin-bottom: 0 !important;
    }
  }
}

.vue-calendar__container {
  /deep/ .container__year {
    height: 35px;
    font-size: 18px;

    .year__chooser[data-v-48911cf0]:nth-child(3) {
      -webkit-box-shadow: inset 0px -2px #409eff;
      box-shadow: inset 0px -2px #409eff;
    }
  }

  /deep/ .container__months {
    padding: 5px;

    .container__month {
      padding: 5px;

      .calendar {
        min-height: 208px;

        .calendar__title {
          height: 30px;
          font-size: 15px;
          margin-bottom: 0px;
        }

        .calendar__body {
          min-width: 250px;

          .calendar__day {
            height: 25px;
            font-size: 12px;
            color: #999;
          }

          .day__weektitle {
            font-size: 12px !important;
            color: #333;
          }
        }
      }

      .calendar:hover {
        z-index: 2;

        @media (min-width: 1024px) {
          transform: scale(1);
          box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  // width: 1200px;
  /deep/ .red {
    &::after {
      content: "休";
      color: #fff;
      font-size: 12px;
      width: 22px;
      height: 22px;
      background-color: red;
      line-height: 22px;
      text-align: center;
      top: 0;
      left: 0;
      right: 0;
    }
  }
}

.box-card {
  position: relative;
  padding-bottom: 30px;

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }

}</style>
